<template>
  <div>
    <Template>
      <template v-if="showDashNumbers">
        <v-col cols="12" class="my-0 py-0">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-card class="pa-2">
                <v-card-text class="card-text-color">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-col cols="10" class="py-0">
                        <div class="text-center">
                            <span class="text-subtitle-1 font-weight-bold color-emitidas">
                              {{ getNotasEmitidas.valor }}
                            </span>
                        </div>
                        <div class="text-center">
                          <span class="text-subtitle-2 d-inline-block text-truncate color-emitidas font-weight-bold" style="max-width: 200px">{{ getNotasEmitidas.quantidade }} Emitida(s)</span>
                        </div>
                      </v-col>
                    </v-col>
                    <v-col cols="3">
                      <v-col cols="11" class="py-0">
                        <div class="text-center">
                          <span class="text-subtitle-1 font-weight-bold color-rejeitadas">{{ getNotasRejeitadas.valor }}</span>
                        </div>
                        <div class="text-center">
                          <span class="text-subtitle-2 d-inline-block text-truncate color-rejeitadas font-weight-bold" style="max-width: 200px">{{ getNotasRejeitadas.quantidade }} Rejeitada(s)</span>
                        </div>
                      </v-col>
                    </v-col>
                    <v-col cols="3">
                      <v-col cols="11" class="py-0">
                        <div class="text-center">
                          <span class="text-subtitle-1 font-weight-bold color-canceladas">{{ getNotasCanceladas.valor }}</span>
                        </div>
                        <div class="text-center">
                          <span class="text-subtitle-2 d-inline-block text-truncate color-canceladas font-weight-bold" style="max-width: 200px">{{ getNotasCanceladas.quantidade }} Cancelada(s)</span>
                        </div>
                      </v-col>
                    </v-col>
                    <v-col cols="3">
                      <v-col cols="11" class="py-0">
                        <div class="text-center">
                          <span class="text-subtitle-1 font-weight-bold color-cadastradas">{{ getNotasCadastradas.valor }}</span>
                        </div>
                        <div class="text-center">
                          <span class="text-subtitle-2 d-inline-block text-truncate color-cadastradas font-weight-bold" style="max-width: 200px">{{ getNotasCadastradas.quantidade }} Cadastrada(s)</span>
                        </div>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="pt-0 my-0 py-0">
              <v-card class="bg-white rounded bg-1 bc-white">
                <v-card-title>
                  <h4>Total de notas por mês</h4>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-card-title>
                <v-card-text class="card-text-color">
                  <GChart type="ColumnChart"
                          style="height: 500px"
                          :settings="{packages:['corechart']}"
                          :data="chartDataQtd"
                          :options="chartOptions"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="pt-0 my-0 py-0">
              <v-card class="bg-white rounded bg-1 bc-white">
                <v-card-title>
                  <h4>Valor de notas por mês</h4>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-card-title>
                <v-card-text class="card-text-color">
                  <GChart type="ColumnChart"
                          style="height: 500px"
                          :settings="{packages:['corechart']}"
                          :data="chartDataVlr"
                          :options="chartOptions"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </template>

      <template v-else>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="6" class="pt-0">
            <v-card class="ma-2 mh-card">
              <v-card-title class="card-title-color">
                <div class="text-body-2 font-weight-bold">Nota Fácil</div>
              </v-card-title>
              <v-card-text class="card-text-color">
                <v-row align="center" justify="center">

                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </Template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Template from "@/components/template/Template"
import {numberToReal, maxFractionDigits, getNameMonth} from "@/util/util"
import {GChart} from 'vue-google-charts'


export default {
  components: {Template, GChart},
  data: () => ({
    loading: false,
    showDashNumbers: false,
    dataDefault: {
      quantidade: 0,
      valor: 0
    },
    chartOptions: {
      legend: {position: 'right'},
      colors: ['#99BC28', '#4b4b4b', '#F01A1A', '#235274']
    }
  }),
  computed: {
    ...mapGetters({
      documentosFiscais: "relatorios/getDocumentosFiscais"
    }),
    getNotasCadastradas() {
      return this.getAttrConsumo("atual", "cadastrado", false)
    },
    getNotasEmitidas() {
      return this.getAttrConsumo("atual", "emitido", false)
    },
    getNotasCanceladas() {
      return this.getAttrConsumo("atual", "cancelado", false)
    },
    getNotasRejeitadas() {
      return this.getAttrConsumo("atual", "rejeitado", false)
    },
    getNotasErro() {
      return this.getAttrConsumo("atual", "erro", false)
    },
    chartDataQtd() {
      return this.carregarChartData("quantidade")
    },
    chartDataVlr() {
      return this.carregarChartData("valor")
    }
  },
  methods: {
    getAttrConsumo(block, attr) {
      if (this.documentosFiscais && this.documentosFiscais[`${block}`]) {
        let {valor, quantidade} = this.documentosFiscais[`${block}`][`${attr}`]
        return {valor: numberToReal(valor), quantidade: maxFractionDigits(quantidade)}
      }
      return {valor: numberToReal(0), quantidade: maxFractionDigits(0)}
    },
    carregarChartData(attr) {
      let chartData = [
        ['Mês', 'Emitidas', 'Rejeitadas', 'Canceladas', 'Cadastradas']
      ]
      let seisMeses = this.documentosFiscais['seis_meses']
      if (this.documentosFiscais && seisMeses && seisMeses.length > 0) {
        for (let mes in seisMeses) {
          let row = this.carregarRowChart(seisMeses, mes, attr)
          chartData.push(row)
        }
      } else {
        let dadosAtual = this.documentosFiscais['atual']
        let row = this.carregarRowChart(dadosAtual, null, attr)
        chartData.push(row)
      }
      return chartData
    },
    carregarRowChart(obj, index, attr) {
      let data = obj
      let mes = new Date().getMonth() + 1
      if (index) {
        data = obj[index]
        mes = index
      }
      if(data) {
        let emitido = data['emitido'] ?? {...this.dataDefault}
        let rejeitado = data['rejeitado'] ?? {...this.dataDefault}
        let cancelado = data['cancelado'] ?? {...this.dataDefault}
        let cadastrado = data['cadastrado'] ?? {...this.dataDefault}
        return [getNameMonth(mes), parseInt(emitido[attr]), parseInt(rejeitado[attr]), parseInt(cancelado[attr]), parseInt(cadastrado[attr])]
      }
      let defaultData = {...this.dataDefault}
      return [getNameMonth(mes), parseInt(defaultData[attr]), parseInt(defaultData[attr]), parseInt(defaultData[attr]), parseInt(defaultData[attr])]
    }
  },
  created() {
    this.loading = true
    if (this.$can('all', 'softhouse_administrador') || this.$can('all', 'super_administrador')) {
      this.showDashNumbers = true;
      this.$store.dispatch("relatorios/buscarRelatorioDocumentosFiscais").finally(() => this.loading = false);
    }
  }
};
</script>

<style>
.card-title-color {
  background-color: #99bc29;
}
</style>
